import { NgModule } from '@angular/core';
import { EmailValidationDirective } from './email-validation-directive'; // Adjust the path

@NgModule({
  declarations: [
    EmailValidationDirective
  ],
  exports: [
    EmailValidationDirective
  ]
})
export class EmailSharedModule { }